<template>
  <div class="card">
    <img
      v-if="imgSource"
      :src="imgSource"
      :class="[{'header-img': imgSmall}]"
      class="card-img-top"
    >
    <div
      v-if="$scopedSlots.header"
      class="card-header bg-transparent"
    >
      <slot name="header" />
    </div>
    <div
      v-if="title"
      class="card-header bg-transparent"
    >
      <template v-if="!$scopedSlots.header">
        <h4 class="mb-0">
          {{ title }}
        </h4>
      </template>
    </div>
    <slot />
    <div
      v-if="$scopedSlots.body"
      class="card-body"
    >
      <slot name="body" />
    </div>
    <div
      v-if="$scopedSlots.footer"
      class="card-footer bg-transparent"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.header-img {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  max-height: 300px;
}

</style>

<script>
export default {
  name: 'Card',
  props: {
    imgSource: {
      default: '',
      type: String
    },
    imgSmall: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    }
  }
}
</script>
