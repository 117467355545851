<template>
  <div>
    <ca-header
      :heading="heading"
      :loading="loading"
    >
      <template #buttonSpace>
        <button
          class="btn btn-link d-flex"
          @click="toggleFavorite"
        >
          <span class="my-auto mr-2">Fahrzeug favorisieren</span>
          <span class="fav-button">
            <i
              class="fas fa-lg fa-heart"
              :class="[favoriteId === undefined ? 'text-gray-4' : 'text-danger', {'favorite-animation': favoriteIdAnimationPending}]"
            />
          </span>
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-card
            v-if="carImages && carImages.length > 0"
            class="mb-3"
          >
            <template #body>
              <div class="row">
                <div class="col-2">
                  <template v-for="(carImage, index) in carImages">
                    <div
                      :key="index"
                      class="slider-thumbnail-wrapper mb-3 cursor-pointer"
                      @click="slide = index"
                    >
                      <img
                        class="img-fluid"
                        :class="{'active': index === slide}"
                        :src="carImage.downloadUrl"
                        alt=""
                      >
                    </div>
                  </template>
                </div>
                <div class="col-10">
                  <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="5000"
                    controls
                    indicators
                  >
                    <template v-for="(carImage, index) in carImages">
                      <b-carousel-slide :key="index">
                        <img
                          slot="img"
                          class="img-fluid d-block w-100"
                          :src="carImage.downloadUrl"
                        >
                      </b-carousel-slide>
                    </template>
                  </b-carousel>
                  <div class="d-block text-right mt-3">
                    <i class="far fa-images" /><span class="font-weight-bold"> {{ slide+1 }} / {{ carImages.length }}</span>
                  </div>
                </div>
              </div>
            </template>
          </ca-card>

          <car-data-detail :car="car" />
          <car-additional-data-detail :car-additional-info="car.carAdditionalInfo" />

          <dev-info :data="{car, carAdditionalInfo: car.carAdditionalInfo, chat, chats, currentChat}" />
        </div>
        <div class="col-12 col-xl-4 col-xxl-3">
          <car-chat-list
            v-if="isMyCar"
            @currentChat="currentChat = $event"
          />
          <template v-if="!isMyCar">
            <car-chat
              v-if="!chat"
              :car-id="car._id"
            />
            <car-chat
              v-if="chat"
              :chat-id="chat._id"
            />
          </template>
          <!-- Chat Besitzer -->
          <car-chat
            v-if="chats && chats[currentChat] && isMyCar"
            :close="true"
            :chat-id="chats[currentChat]._id"
            @close="currentChat = undefined"
          />
          <h4 class="mt-4">
            Ähnliche Fahrzeuge
          </h4>
          <div class="my-4">
            Keine ähnlichen Fahrzeuge gefunden.
          </div>
          <!-- <template v-for="(similarCar, index) in similarCars">
            <car-card
              :key="index"
              :car-id="similarCar._id"
              :img-source="similarCar.imgSource"
              :price="similarCar.price"
              :title="similarCar.title"
              :description="similarCar.description"
              :car-type="similarCar.carType"
            />
          </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

$bubble-d: 4.5rem; // bubble diameter
$bubble-r: .5*$bubble-d; // bubble-radius

.fav-button{
  display:  inline-flex;
  width: 28px;
  height: 28px;
  i{
    align-self: center;
    margin: auto;
    &.favorite-animation{
      will-change: font-size;
      animation: heart 500ms cubic-bezier(.17, .89, .32, 1.8);
    }
  }
}

@keyframes heart {
    0%, 17.5% { font-size: 0; }
}

.slider-thumbnail-wrapper{
    img{
        border-radius: 5px;
        border: 3px white solid;
      &.active{
        border: 3px $primary solid;
      }
    }
}
</style>

<script>
import CaHeader from '@/components/Layout/Header'
import DevInfo from '@/components/DevInfo'
import CaCard from '@/components/Card'
import formatDate from '@/filters/formatDate.js'
import CarChat from '@/components/CarChat'
import CarChatList from '@/components/CarChatList'
// import CarCard from '@/components/CarInPoolCard'
import CarAdditionalDataDetail from '@/components/CarAdditionalData/CarAdditionalDataDetail'
import CarDataDetail from '@/components/CarDataDetail'

export default {
  name: 'CarPoolDetail',
  components: {
    CaHeader,
    DevInfo,
    CaCard,
    CarChat,
    CarChatList,
    // CarCard,
    CarAdditionalDataDetail,
    CarDataDetail
  },
  filters: {
    formatDate,
    monthOptions (val) {
      switch (val) {
      case 0: return ''
      case 1: return 'Januar'
      case 2: return 'Februar'
      case 3: return 'März'
      case 4: return 'April'
      case 5: return 'Mai'
      case 6: return 'Juni'
      case 7: return 'Juli'
      case 8: return 'August'
      case 9: return 'September'
      case 10: return 'Oktober'
      case 11: return 'November'
      case 12: return 'Dezember'
      }
    }
  },
  data () {
    return {
      slide: 0,
      loading: true,
      chat: undefined,
      currentChat: undefined,
      car: null,
      chats: undefined,
      newMessage: '',
      sendMessagePending: false,
      favoriteId: undefined,
      favoriteIdAnimationPending: false,
      similarCars: [
        {
          _id: '5d51315f091c29001b195e15',
          imgSource: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXZDmpIAWLtJTIa0O6xqCbZxObI0BvyBnjHrq7l4pXT63l1Vml-g',
          price: 500000,
          title: 'Super schnelles Auto',
          description: 'EZ 02/2019, 90.000 km, Benzin, 77kW (105PS) ca. 5,9l/100km (komb.), ca. 139g. CO2/km (komb.)'
        },
        {
          _id: '5d51315f091c29001b195e15',
          carType: 'Cabrio',
          price: 50000,
          title: 'Cabrio/Roadster',
          description: 'EZ 02/2019, 90.000 km, Benzin, 77kW (105PS) ca. 5,9l/100km (komb.), ca. 139g. CO2/km (komb.)'
        },
        {
          _id: '5d51315f091c29001b195e15',
          imgSource: 'https://i.auto-bild.de/ir_img/2/1/5/5/1/0/9/Guenstiges-Elektro-Auto-Life-474x316-3283c45d1e955e67.jpg',
          price: 500000,
          title: 'Super schnelles Auto',
          description: 'EZ 02/2019, 90.000 km, Benzin, 77kW (105PS) ca. 5,9l/100km (komb.), ca. 139g. CO2/km (komb.)'
        }
      ]
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    isMyCar () {
      return this.car.userId === this.me._id
    },
    carImages () {
      return this.car.carAdditionalInfo.images
    },
    heading () {
      if (!this.car) return ''
      return this.car.licensePlate
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async toggleFavorite () {
      try {
        if (this.favoriteId === undefined) {
          const carFavorite = await this.$store.dispatch('car-favorite/create', {
            userId: this.me._id,
            carId: this.car._id
          })
          this.favoriteId = carFavorite._id
          this.favoriteIdAnimationPending = true
        } else {
          this.favoriteIdAnimationPending = false
          await this.$store.dispatch('car-favorite/remove', this.favoriteId)
          this.favoriteId = undefined
        }
      } catch (error) {
        console.error(error)
      }
    },
    async startNewChat () {
      try {
        this.loading = true
        await this.$store.dispatch('car-chat/create', {
          carId: this.car._id,
          chatIds: [this.me.chatId]
        })
        await this.fetchData()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.car = await this.$store.dispatch('car/get', this.$route.params.carId)
        this.favoriteId = (await this.$store.dispatch('car-favorite/find', { query: { userId: this.me._id, carId: this.car._id, $limit: 1 } })).data[0]
        if (this.isMyCar) {
          this.chats = await this.$store.dispatch('car-chat/find', {
            query: {
              $client: {
                paginate: false
              },
              carId: this.car._id
            }
          })
        } else {
          this.chat = (await this.$store.dispatch('car-chat/find', {
            query: {
              carId: this.car._id,
              chatIds: this.me.chatId
            }
          })).data[0]
        }

        if (this.car.carAdditionalInfoId != null) {
          await this.$store.dispatch('car-additional-info/get', this.car.carAdditionalInfoId)
        }
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
